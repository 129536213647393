import React, { useState } from "react"
// var mailgun = require('mailgun.js');

async function enviarEmail() {
    // var mg = mailgun.client({ username: 'api', key: 'key-c78a5e3692cb4c4bbc2ef9657817ef47' });

    // mg.messages.create('email.pablo384.com', {
    //     from: "Excited User <contacto@pablo384.com>",
    //     to: ["pabloreinso0@gmail.com"],
    //     subject: "Hello",
    //     text: "Testing some Mailgun awesomness!",
    //     html: "<h1>Testing some Mailgun awesomness!</h1>"
    // })
    // var formData = new FormData();
    // // var fileField = document.querySelector("input[type='file']");

    // formData.append('from', 'Mailgun Sandbox <contacto@pablo384.com>')
    // formData.append('to', 'pabloreinso0@gmail.com');
    // formData.append('subject', 'PRUEBA');
    // formData.append('text', 'This is cool !');
    // // formData.append('asunto', 'abc123');
    // // formData.append('nombre', 'abc123');
    // // formData.append('email', 'abc123');
    // // formData.append('mensaje', 'abc123');
    // // formData.
    // mg.se
    // var myHeaders = new Headers({
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     'Authorization': 'api:key-c78a5e3692cb4c4bbc2ef9657817ef47'
    // });
    // let body = "from=Mailgun Sandbox <contacto@pablo384.com>&to=" + "pabloreinso0@gmail.com" + "&subject=" + "PRUEBA" + "&text=" + "This is cool !";

    // let res = await fetch('https://api.mailgun.net/v3/email.pablo384.com/messages', {
    //     method: 'POST',
    //     body: body,
    //     headers: myHeaders
    // })
    // console.log(res);

}
function contacto() {
    return (
        <div>

            <h1>HOLA CONTACTO</h1>
            <button onClick={enviarEmail} >send</button>
        </div>
    )
}
export default contacto;